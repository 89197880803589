import axios from "axios";

export default {
  async getListCount(list_name) {
    return await axios
      .get("private-allocation/get-list-count/" + list_name)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getAllOffers(from_date, to_date) {
    return await axios
      .get(
        `private-vendor-offer/get-all-offer?fromDate=${from_date}&toDate=${to_date}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getOffersDetails(id) {
    return await axios
      .get("private-vendor-offer/get-offer-details/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getOffersDetailsWithTeatype(id, tea_type = []) {
    return await axios
      .get("Season-vendor-offer/get-offer-by-season-offer-with-tea-type", {
        params: {
          id,
          tea_type,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async downloadOfferDetails(id) {
    axios({
      method: "get",
      url: `private-vendor-offer/download-offer-details/${id}`,
      responseType: "blob",
    })
      .then((res) => {
        console.log("RES", res);
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Private Offer Details.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },

  // async getVendors() {
  //   let type = "PRIVATE";
  //   return await axios
  //     .get("vendors/vendor/by/type/" + type)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       throw new Error(err.response.data.message);
  //     });
  // },
  // async getSeason(vendor_id) {
  //   return await axios
  //     .get(`private-vendor-offer/vendor-offer-season-by-vendor-id/${vendor_id}`)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       throw new Error(err.response.data.message);
  //     });
  // },

  // async getOffers(vendor_id, season_id) {
  //   return await axios
  //     .get(
  //       `private-vendor-offer/get-offers-by-vendor-and-season?season_id=${season_id}&vendor_id=${vendor_id}`
  //     )
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       throw new Error(err.response.data.message);
  //     });
  // },

  async getTeaTypes() {
    return await axios
      .get(`/tea-type`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getGrade() {
    return await axios
      .get(`/grade`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getRole() {
    return await axios
      .get(`/role`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async searchData(id) {
    return await axios
      .get("Season-vendor-offer/get-offer-by-season-offer/" + id, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async filterData(tea_type, offer_no, season_id, vendor_id, grade_code) {
    return await axios
      .get("Season-vendor-offer/private-vendor-offer-filter", {
        params: {
          tea_type: tea_type,
          offer_no: offer_no,
          season_id: season_id,
          vendor_id: vendor_id,
          grade_code: grade_code,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(req) {
    axios
      .post("private-allocation", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};

// grade_code?: string;
