<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                >From (Start date)</label
              >

              <v-menu
                ref="start_date_menu"
                v-model="start_date_menu"
                :close-on-content-click="false"
                :return-value.sync="start_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.start_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="Start Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="editedItem.start_date"
                  :max="editedItem.end_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="start_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_date_menu.save(editedItem.start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                >To (End date)</label
              >

              <v-menu
                ref="end_date_menu"
                v-model="end_date_menu"
                :close-on-content-click="false"
                :return-value.sync="end_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.end_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="To Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="editedItem.end_date"
                  :min="editedItem.start_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="end_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_date_menu.save(editedItem.end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-btn
                @click="searchData()"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  px-6
                  mt-11
                "
                >Search</v-btn
              ></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-5">
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="allOffers"
            mobile-breakpoint="0"
            class="table"
            fixed-header
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-text-field
                      hide-details
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                      "
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      v-model="search"
                      placeholder="Search with : Vendor / Offer / Season"
                    >
                      <template slot="prepend-inner">
                        <v-icon color="#adb5bd" size=".875rem"
                          >fas fa-search</v-icon
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div class="text-center">
                <v-chip class="ma-2" small color="#66BB6A" label outlined>
                  {{ item.total_offer }} -Lot(s)
                </v-chip>
                <!-- <v-chip class="ma-2" small color="green" label outlined>
                {{ item.allocated }} -Allocated
              </v-chip> -->
                <v-chip class="ma-2" small color="#29B6F6" label outlined>
                  {{ item.pending }} -Pending
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.allocationDetails`]="{ item }">
              <v-btn
                :ripple="false"
                icon
                text
                width="auto"
                class="
                  pe-4
                  ps-0
                  mb-0
                  text-default text-capitalize
                  icon-move-right
                  align-center
                "
                @click="allocationDetails(item.id)"
                v-if="item.allocated > 0"
              >
                Details
                <v-icon right dark size="12" class="ms-0 mt-1">
                  fa fa-arrow-right
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.allocate`]="{ item }">
              <v-btn
                :ripple="false"
                icon
                text
                width="auto"
                class="
                  pe-4
                  ps-0
                  mb-0
                  text-default text-capitalize
                  icon-move-right
                  align-center
                "
                @click="allocateList(item)"
                v-if="item.pending > 0"
              >
                Allocate
                <v-icon right dark size="12" class="ms-0 mt-1">
                  fa fa-arrow-right
                </v-icon>
              </v-btn>
            </template>
            <!-- <template v-slot:[`item.allocationDetails`]="{ item }">
              <v-btn text color="red" @click="allocationDetails(item.id)" v-if="item.allocated > 0"> Details </v-btn>
            </template>
            <template v-slot:[`item.allocate`]="{ item }">
              <v-btn text color="green" @click="allocateList(item)" v-if="item.pending > 0"> Allocate </v-btn>
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Dialogue Details -->

    <v-dialog v-model="detailsDialogue" persistent fullscreen>
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="ma-5"
          ><v-data-table
            :headers="headersDetails"
            :items="details"
            mobile-breakpoint="0"
            class="table"
            fixed-header
            disable-pagination
            hide-default-footer
            ><template v-slot:top class="mt-5">
              <v-toolbar flat>
                <v-toolbar-title></v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn text color="green" @click="detailsDialogue = false">
                  close
                </v-btn>
                <v-btn text color="green" @click="downloadOfferDetails">
                  Download
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialogue Selection -->
    <v-dialog v-model="listDialogue" persistent fullscreen>
      <v-card class="card-shadow border-radius-xl mt-6">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="4"
              ><label class="text-md font-weight-bolder ms-1 mandatory"
                >Tea Type</label
              >
              <v-autocomplete
                multiple
                v-model="tea_type"
                :items="tea_types"
                item-text="tea_type_name"
                item-value="id"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-5
                  mb-0
                "
                placeholder="Select a Tea Type"
                outlined
                single-line
                height="48"
                ><template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index === 0"
                    label
                    color="bg-default"
                    class="py-1 px-2 my-0"
                  >
                    <span class="text-white text-caption ls-0">{{
                      item.tea_type_name
                    }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ tea_type.length - 1 }}
                    Types)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-btn
                @click="searchDataWithTeaType"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  py-3
                  px-6
                  mt-12
                "
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="card-padding">
          <v-data-table
            v-model="selected"
            :headers="headersList"
            :items="filterResult"
            item-key="pid"
            show-select
            mobile-breakpoint="0"
            class="table"
            fixed-header
            disable-pagination
            hide-default-footer
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
          >
            <template v-slot:top class="mt-5">
              <v-toolbar flat>
                <v-toolbar-title>Private Allocation</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn
                  @click="allocate"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mt-5
                  "
                  v-if="filterResult.length > 0"
                  >Allocate
                </v-btn>
                <v-btn
                  text
                  color="green"
                  class="mt-5"
                  @click="
                    (listDialogue = false),
                      (selected = []),
                      ((filterResult = []), (tea_type = null))
                  "
                >
                  Close
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table></v-card-text
        >

        <v-card-actions v-if="filterResult.length > 0">
          <v-row>
            <v-col offset-md="5" class="mt-5"
              ><v-btn
                @click="allocate"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  mb-10
                "
                >Allocate
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- List Name Dialogue -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl" height="300px">
        <v-card-text class="card-padding">
          <v-form ref="frmSave">
            <v-container class="px-0">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="list_name"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Enter List Name"
                    :rules="[(v) => !!v || 'List Name is required']"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="role"
                    :items="roles"
                    item-text="role"
                    item-value="id"
                    return-object
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-2
                      mb-0
                    "
                    placeholder="Select a Role"
                    :rules="[(v) => !!v || 'Role is Required']"
                    outlined
                    single-line
                    height="43"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="8">
                  <v-btn
                    @click="saveData"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    >Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
export default {
  data() {
    return {
      overlay: false,
      allOffers: [],
      masterID: 0,
      offer_id: 0,
      headers: [
        {
          text: "Vendor",
          value: "vendor",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,

          align: "left",
        },
        {
          text: "Season",
          value: "season",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "offer-no",
          value: "offer_no",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,

          align: "left",
        },
        {
          text: "",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "",
          value: "allocate",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "",
          value: "allocationDetails",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
      ],
      details: [],
      headersDetails: [
        {
          text: "Grade",
          value: "grade_name",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "lot",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "Tea Type",
          value: "tea_type",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "Package",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "gross weight",
          value: "gross_net_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "net weight",
          value: "net_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "inv.Weight",
          value: "invoice_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
        {
          text: "Offer Price",
          value: "offer_price",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },

        {
          text: "Inv.no",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },

        {
          text: "List Name",
          value: "list_name",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },

        {
          text: "Allocated to",
          value: "allocatedto",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          // width: 300,
          align: "left",
        },
      ],
      detailsDialogue: false,
      // Select
      headersList: [
        {
          text: "Grade",
          value: "grade_code",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Lot ",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Mark Code ",
          value: "mark_code",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Tea Type",
          value: "tea_type",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Packages",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Gross Net Weight",
          value: "gross_net_weight",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Net Weight",
          value: "net_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Invoice Weight",
          value: "invoice_weight",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Offer Price",
          value: "offer_price",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
          width: 150,
          align: "center",
        },
      ],
      search: "",
      listDialogue: false,
      selected: [],
      filterResult: [],
      season: "",
      vendor: "",
      offer: "",
      // List Name and save
      dialog: false,
      list_name: "",
      role: null,
      roles: [],
      tea_types: [],
      tea_type: null,
      start_date_menu: false,
      end_date_menu: false,
      editedItem: {
        start_date: null,
        end_date: null,
      },
    };
  },
  mounted() {
    this.initialize();
    this.setDates();
  },
  methods: {
    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.editedItem.start_date = this.formatDate(d);
      this.editedItem.end_date = this.formatDate(d1);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    clearData() {
      this.tea_type = null;
      this.allOffers = [];
      this.role = "";
      this.list_name = "";
      this.filterResult = [];
      this.selected = [];
      this.tea_types = [];
      this.tea_type = null;
    },
    async initialize() {
      this.clearData();
      this.tea_types = await api.getTeaTypes();
      this.allOffers = await api.getAllOffers(
        this.editedItem.start_date,
        this.editedItem.end_date
      );
      // this.allOffers.sort(function (a, b) {
      //   return b.pending - a.pending;
      // });
      this.roles = await api.getRole();
    },
    async allocationDetails(id) {
      this.offer_id = id;
      this.details = await api.getOffersDetails(id);
      this.detailsDialogue = true;
    },

    async downloadOfferDetails() {
      await api.downloadOfferDetails(this.offer_id);
    },
    async allocateList(item) {
      try {
        this.filterResult = await api.searchData(item.id);
        if (this.filterResult.length > 0) {
          this.masterID = item.id;
          this.listDialogue = true;

          this.season = item.season;
          this.vendor = item.vendor_code;
          this.offer = item.offer_no;
        } else {
          this.noDataFoundAlert("No Data Found...!");
        }
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    async allocate() {
      if (this.selected.length > 0) {
        this.dialog = true;
        this.list_name = "";
        let grade = [];
        let mark = [];
        let teatype = [];
        this.selected.forEach((el) => {
          grade.push(el.grade_code);
          mark.push(el.mark_code);
          teatype.push(el.tea_type);
        });
        let _grade = [...new Set(grade)];

        let _teatype = [...new Set(teatype)];

        let c =
          this.vendor +
          "-" +
          _teatype.sort().join("-") +
          "-" +
          this.season +
          "-" +
          this.offer;

        let count = await api.getListCount(c);

        this.list_name = c + "-" + count[0].count;
      } else {
        this.showWarningAlert("Check at least One Row");
      }
    },
    saveValidation() {
      return this.$refs.frmSave.validate();
    },
    async searchDataWithTeaType() {
      try {
        console.log("Searching", this.tea_type);
        if (this.tea_type) {
          this.filterResult = [];
          let r = await api.getOffersDetailsWithTeatype(
            this.masterID,
            this.tea_type
          );
          if (r.length > 0) {
            this.filterResult = r;
          } else {
            this.noDataFoundAlert("No Data Found...!");
          }
        } else {
          this.showWarningAlert("Please Select Tea Type");
        }
      } catch (error) {}
    },
    async searchData() {
      this.clearData();
      this.allOffers = await api.getAllOffers(
        this.editedItem.start_date,
        this.editedItem.end_date
      );
    },
    async saveData() {
      if (this.saveValidation()) {
        this.overlay = true;
        let req = {
          season_vendor_offer_id: this.selected[0].season_vendor_offer_id,
          list_name: this.list_name,
          assigned_to: this.role,
        };
        let child = [];
        this.selected.forEach((el) => {
          let d = {
            lot_no: el.lot_no,
            private_vendor_offer: el.pid,
          };
          child.push(d);
        });

        Object.assign(req, { child });
        await api
          .saveData(req)
          .then(() => {
            this.showSuccessAlert("Data saved");
            //Removing allocated row from table
            this.filterResult.forEach((elm) => {
              this.selected.splice(
                this.selected.findIndex((el) => el == elm),
                1
              );
            });

            if (this.selected.length == 0) {
              this.initialize();
              this.listDialogue = false;
            }
          })
          .catch((err) => {
            this.showErrorAlert(err);
          })
          .finally(() => {
            this.overlay = false;
            this.dialog = false;
          });
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 3000,
        icon: "success",
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },

    noDataFoundAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>

<style></style>
